<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VTextArea">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <div :class="{ 'relative': props.maxLength }">
      <textarea
        :id="id"
        v-model="modelValueInternal"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :placeholder="props.placeholder"
        :maxlength="props.maxLength"
        :name="props.name"
        class="block w-full rounded-md border-0 py-1.5 text-neutral-950 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm leading-6"
        :class="computedInputClass"
        :aria-describedby="descriptionId"
        @keydown="handleKeyDown"
        @paste="handlePaste"
        @blur="updateModelValue"
      />
      <div v-if="props.maxLength" class="absolute bottom-0 right-0 p-2 pr-3 whitespace-nowrap text-xs leading-4 font-normal" :class="{ 'text-neutral-500': !props.disabled, 'text-neutral-250': props.disabled }">
        {{ modelValueInternalLength }} / {{ props.maxLength }}
      </div>
    </div>
    <VSValidation v-if="validationResult" :type="validationResult[0]" :message="validationResult[1]" />
    <VSDescription v-if="props.description" :id="descriptionId">{{ props.description }}</VSDescription>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import { useElementId, useDescriptionElementId } from '../../utils/utils';
import { useBuffering } from './helpers/buffering';
import { handleEvents } from './helpers/input';
import { SHARED_PROPS_DEFAULTS, VALIDATION_PROPS_DEFAULTS, type SharedProps, type ValidationProps } from './helpers/types';
import VSValidation from './components/VSValidation.vue';
import { createDefaultValidation, useValidation } from './helpers/validations';

defineOptions({
  name: 'VTextArea'
})

const props = withDefaults(
  defineProps<SharedProps & ValidationProps<string> & {
    placeholder?: string
    whitelist?: string[]
    maxLength?: number
    buffered?: boolean
    required?: boolean
  }>(),
  {
    ...SHARED_PROPS_DEFAULTS,
    ...VALIDATION_PROPS_DEFAULTS,
    placeholder: undefined,
    required: false,
    whitelist: undefined,
    maxLength: undefined,
    buffered: false
  }
)

const id = useElementId(props.id)
const descriptionId = useDescriptionElementId(props.description, id.value)

const modelValue = defineModel<string | null | undefined>({ required: true })

const { modelValueInternal, updateModelValue } = useBuffering(modelValue, props)

const modelValueInternalLength = computed(() => modelValueInternal.value?.length ?? 0)

const { validationVisible, validationResult, isValid, isValidationError, isValidationWarning } = useValidation(
  modelValueInternal,
  props,
  createDefaultValidation<string>(props)
)

defineExpose({
  isValid
})

const computedInputClass = computed(() => ({
  'text-neutral-400 bg-neutral-150 ring-neutral-200 opacity-50 cursor-not-allowed': props.disabled,
  'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled,
  'ring-red-300': isValidationError.value,
  'ring-yellow-300': isValidationWarning.value
}))

const { handleKeyDown, handlePaste } = handleEvents(props, validationVisible)
</script>